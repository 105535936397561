import React from "react";
import {
  Card, CardBody, Container, Row, Col, Spinner, Alert, Badge
} from "reactstrap";
import "./DepositStatus.scss";
import { PerfectMoneyForm } from "./Methods/PerfectMoney";

export default function DepositStatus({ result, selectedPaymentMethod, loading, isSuccess, t, toggleOpen, type, isError }) {
  const renderContent = () => {
    switch (selectedPaymentMethod) {
      case "FINITIC_PAY":
        if (result?.result?.url) {
          return (
            <>
              <h4 className="mb-4">{t("Please complete payment")}</h4>
              <div className="iframe-container">
                <iframe
                  src={result?.result?.url}
                  title="Finitic Pay"
                  className="w-100"
                />
              </div>
            </>
          );
        } else {
          return renderErrorState();
        }
      case "EPAYME":
        if (loading) {
          return <Alert color="info">{t("Waiting for you to complete payment")}</Alert>;
        } else if (isSuccess) {
          return <Alert color="success">{t("Payment completed transaction will be added within 15 minutes")}</Alert>;
        } else if (isError) {
          return <Alert color="danger">{t("Payment failed")}</Alert>;
        }
        break;
      case "PERFECT_MONEY":
        return <PerfectMoneyForm result={result.result} />;
      default:
        if (isSuccess) {
          return renderSuccessState();
        } else if (isError) {
          return renderErrorState();
        } else {
          return null;
        }
    }
  };

  const renderSuccessState = () => (
    <Card className="text-center mb-4">
      <CardBody>
        <h1 className="display-4 mb-4">
          {t("Yay!")} <i className="bx bx-wink-smile"></i>
        </h1>
        <p className="lead">{t("Pending Deposit Thank You")}</p>
        <p>
          <Badge color="success" className="p-2 fs-5">
            {type === "fiatDeposit" ? result?.result?.amount?.$numberDecimal : result?.result?.amount} {" "}
            {type === "fiatDeposit" ? result?.result?.currency : result?.result?.currency}
          </Badge>
        </p>
        <p className="text-muted">
          {t("Your transaction ID is")} {result?.result?.recordId}
        </p>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col xs="6">
                <p className="text-muted mb-1">{t("Status")}</p>
                <p className="fs-5 text-success">{t(result?.result?.status)}</p>
              </Col>
              <Col xs="6">
                <p className="text-muted mb-1">{t("GATEWAY")}</p>
                <p className="fs-5">{result.result?.gateway}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );

  const renderErrorState = () => (
    <Card className="text-center mb-4">
      <CardBody>
        <h1 className="display-4 mb-4">
          {t("Oops!")} <i className="bx bx-sad"></i>
        </h1>
        <p className="lead">{t("Your Deposit Request Not Successfully Created")}</p>
        <Alert color="danger" className="mt-4">
          {result.message}
        </Alert>
      </CardBody>
    </Card>
  );

  return (
    <Container className="deposit-status">
      {loading && (
        <div className="text-center my-5">
          <Spinner color="primary" />
          <p className="mt-3">{t("Processing your request...")}</p>
        </div>
      )}
      {!loading && renderContent()}
    </Container>
  );
}